import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import './ErrorMessageStyle.scss';

const FormikErrorMessage = ({ fieldName }) => {
  const { t } = useTranslation(['errors']);
  return (
    <ErrorMessage name={fieldName} data-testid={`error-${fieldName}`}>
      { (msg) => <div className="error-message">{(t(msg.key, msg.values))}</div> }
    </ErrorMessage>
  );
};

FormikErrorMessage.propTypes = {
  fieldName: PropTypes.string.isRequired,
};
export default FormikErrorMessage;
