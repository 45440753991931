import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import './InputStyle.scss';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';
import Label from '../Label/Label';

const FormikInput = (props) => {
  const {
    label,
    labelClassName,
    id,
    fieldType,
    fieldName,
    fieldPlaceholder,
    className,
    icon,
    minorLabel,
    hint,
    multiple,
    fieldTestId,
    errorTestId,
    withClearIcon,
  } = props;
  return (
    <div className={`app-input ${className}`}>
      <Label htmlFor={id} label={label} labelClassName={labelClassName} />
      {minorLabel ? <span className="minor-title">{minorLabel}</span> : null}
      <div className="d-inline-block position-relative w-100 input-clear-btn">
        <Field name={fieldName}>
          {({
            field,
            form: { setFieldValue },
          }) => (
            <div>
              <input
                id={id}
                type={fieldType}
                placeholder={fieldPlaceholder}
                data-testid={fieldTestId}
                multiple={multiple}
                {...field}
              />
              {withClearIcon
                ? (
                  <Button
                    variant="transparent"
                    onClick={() => setFieldValue(fieldName, '')}
                    type="button"
                    className="clear-btn"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                ) : null}
            </div>
          )}
        </Field>
      </div>
      {hint ? <small className="hint">{hint}</small> : null}
      <FormikErrorMessage fieldName={fieldName} data-testid={errorTestId} />
      {icon}
    </div>
  );
};

FormikInput.propTypes = {
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  id: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  fieldPlaceholder: PropTypes.string,
  fieldName: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  minorLabel: PropTypes.node,
  hint: PropTypes.string,
  multiple: PropTypes.bool,
  fieldTestId: PropTypes.string,
  errorTestId: PropTypes.string,
  withClearIcon: PropTypes.bool,
};
FormikInput.defaultProps = {
  labelClassName: null,
  label: null,
  fieldPlaceholder: null,
  fieldName: null,
  className: null,
  icon: null,
  minorLabel: null,
  hint: '',
  multiple: false,
  fieldTestId: '',
  errorTestId: '',
  withClearIcon: false,
};
export default FormikInput;
