/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenderEnum = "female" | "male" | "%future added value";
export type QualificationCategoryEnum = "consultant" | "specialist" | "%future added value";
export type RadiologistQueryVariables = {||};
export type RadiologistQueryResponse = {|
  +radiologist: ?{|
    +dbId: number,
    +name: string,
    +email: string,
    +phone: string,
    +telegramEnabled: boolean,
    +activeContract: ?{|
      +dbId: number
    |},
    +radiologistProfile: ?{|
      +gender: GenderEnum,
      +dateOfBirth: any,
      +nationality: string,
      +country: string,
      +city: string,
      +street: string,
      +examTypeIds: $ReadOnlyArray<number>,
      +qualificationCategory: QualificationCategoryEnum,
      +practiceSince: number,
    |},
  |}
|};
export type RadiologistQuery = {|
  variables: RadiologistQueryVariables,
  response: RadiologistQueryResponse,
|};
*/


/*
query RadiologistQuery {
  radiologist {
    dbId
    name
    email
    phone
    telegramEnabled
    activeContract {
      dbId
      id
    }
    radiologistProfile {
      gender
      dateOfBirth
      nationality
      country
      city
      street
      examTypeIds
      qualificationCategory
      practiceSince
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "telegramEnabled",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateOfBirth",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nationality",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "street",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "examTypeIds",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "qualificationCategory",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "practiceSince",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RadiologistQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Radiologist",
        "kind": "LinkedField",
        "name": "radiologist",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contract",
            "kind": "LinkedField",
            "name": "activeContract",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RadiologistProfile",
            "kind": "LinkedField",
            "name": "radiologistProfile",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "RadiologistQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Radiologist",
        "kind": "LinkedField",
        "name": "radiologist",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contract",
            "kind": "LinkedField",
            "name": "activeContract",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "RadiologistProfile",
            "kind": "LinkedField",
            "name": "radiologistProfile",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f27cf3f3f14ffdddb3a44574195a47e4",
    "id": null,
    "metadata": {},
    "name": "RadiologistQuery",
    "operationKind": "query",
    "text": "query RadiologistQuery {\n  radiologist {\n    dbId\n    name\n    email\n    phone\n    telegramEnabled\n    activeContract {\n      dbId\n      id\n    }\n    radiologistProfile {\n      gender\n      dateOfBirth\n      nationality\n      country\n      city\n      street\n      examTypeIds\n      qualificationCategory\n      practiceSince\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b070b751f00dde70587301ce7868384';

module.exports = node;
