import React, { Suspense } from 'react';
import {
  Row, Col, Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLazyLoadQuery } from 'react-relay/hooks';
import Title from '../Shared/Title/Title';
import RadiologistProfileForm from './RadiologistProfileForm';
import ProfileCard from './ProfileCard';
import HelmetWrapper from '../../hocs/HelmetWrapper/HelmetWrapper';
import RadiologistQuery from './RadiologistQuery/RadiologistQuery';
import Loader from '../Shared/Loader/Loader';

const EditRadiologistProfile = () => {
  const { t } = useTranslation(['editProfile']);
  const { radiologist } = useLazyLoadQuery(RadiologistQuery);

  return (
    <>
      <HelmetWrapper>
        <title>{t('titles.profileSettings')}</title>
      </HelmetWrapper>
      <Title
        title={t('titles.profileSettings')}
        className="page-head"
      />
      <Container>
        <Row>
          <Col lg={{ span: 3, offset: 0 }} md={{ span: 6, offset: 3 }}>
            <ProfileCard user={radiologist} />
          </Col>
          <Suspense fallback={<Loader />}>
            <RadiologistProfileForm radiologist={radiologist} />
          </Suspense>
        </Row>
      </Container>
    </>
  );
};

export default EditRadiologistProfile;
