import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';
import Label from '../Label/Label';
import './TextareaStyle.scss';

const FormikTextarea = (props) => {
  const {
    label,
    labelClassName,
    id,
    fieldName,
    fieldPlaceholder,
    rows,
    dataTestId,
  } = props;
  return (
    <div>
      <Label htmlFor={id} label={label} labelClassName={labelClassName} />
      <Field id={id} as="textarea" name={fieldName} rows={rows} placeholder={fieldPlaceholder} data-testid={dataTestId} />
      <FormikErrorMessage fieldName={fieldName} />
    </div>
  );
};

FormikTextarea.propTypes = {
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  id: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  fieldPlaceholder: PropTypes.string,
  rows: PropTypes.number,
  dataTestId: PropTypes.string,
};
FormikTextarea.defaultProps = {
  labelClassName: null,
  label: null,
  fieldName: null,
  fieldPlaceholder: '',
  rows: 3,
  dataTestId: '',
};
export default FormikTextarea;
