import { format } from 'date-fns';

// TODO: Replace moment usage with date-fns
// because moment has a pretty large bundle size
import moment from 'moment';

export const toDate = (dateString = null) => (dateString ? moment(dateString).format('YYYY-MM-DD') : null);
export const formattedDate = (date, isDatetime = false) => {
  if (!date) return null;
  try {
    const formatString = (isDatetime === true) ? 'd MMMM yyyy, hh:mm a' : 'd MMMM, yyyy';
    return format(new Date(date), formatString);
  } catch (_error) {
    return null;
  }
};
