/* eslint-disable react/jsx-props-no-spreading, no-param-reassign */
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Label from '../Label/Label';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';
import '../FormikInput/InputStyle.scss';
import './UploadedFileStyle.scss';
import Button from '../../Button/Button';
import FileSize from '../../../../utils/FileSize';
import { difference } from '../../../../utils/files';

const DragDropFileInput = ({
  id, setFieldValue, isMulti, label, prefix, accept, maxFiles,
  labelClassName, fieldName, fieldPlaceholder, minSize, maxSize,
  className, icon, minorLabel, hint, fieldTestId,
}) => {
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const { t } = useTranslation(['translation', 'errors']);

  const allowedMaxFiles = isMulti ? maxFiles : 1;

  const {
    acceptedFiles, getRootProps, getInputProps, fileRejections,
  } = useDropzone(
    {
      maxFiles: allowedMaxFiles,
      accept: [accept].join(', '),
      multiple: isMulti,
      validator: (file) => {
        if (!file) return null;

        if (minSize && file.size < minSize.sizeInBytes()) {
          return {
            code: 'min-file-size',
            message: t('errors:smFile', { size: minSize.size, unit: t(`units.${minSize.unit}`) }),
          };
        }

        if (maxSize && file.size > maxSize.sizeInBytes()) {
          return {
            code: 'max-file-size',
            message: t('errors:lgFile', { size: maxSize.size, unit: t(`units.${maxSize.unit}`) }),
          };
        }

        return null;
      },

    },
  );

  useEffect(() => {
    const files = [...accepted, ...rejected];

    setFieldValue({ [fieldName]: isMulti ? files : files[0] });
  }, [accepted, rejected]);

  useEffect(() => {
    if (isMulti) {
      const currentAcceptedDiff = difference(accepted, acceptedFiles);
      const currentRejectedDiff = difference(rejected, fileRejections);
      setAccepted([...currentAcceptedDiff, ...acceptedFiles]);
      setRejected([...currentRejectedDiff, ...fileRejections]);
    } else {
      setAccepted([...acceptedFiles]);
      setRejected([...fileRejections]);
    }
  }, [acceptedFiles, fileRejections]);

  const deleteAndCleanErrors = (modifiedRejected) => {
    const gotAccepted = [];
    const gotRejected = [];
    const filesMetaData = {
      rejectedLength: modifiedRejected.length,
      acceptedLength: accepted.length,
      filesLength: modifiedRejected.length + accepted.length,
    };
    // Temp solution
    modifiedRejected.forEach((file) => {
      if (filesMetaData.rejectedLength && filesMetaData.filesLength <= allowedMaxFiles) {
        const errorIndex = file.errors.findIndex((error) => error.code === 'too-many-files');
        if (errorIndex > -1) file.errors.splice(errorIndex, 1);
        if (file.errors.length) {
          gotRejected.push(file);
        } else {
          gotAccepted.push(file.file);
        }
      } else {
        gotRejected.push(file);
      }
    });
    setAccepted([...accepted, ...gotAccepted]);
    setRejected([...gotRejected]);
  };

  const handleRemoveFile = (file, isAccepted = false) => () => {
    if (isAccepted) {
      setAccepted([...accepted.filter((currentFile) => currentFile !== file)]);
    } else {
      deleteAndCleanErrors([...rejected.filter((currentFile) => currentFile.file !== file)]);
    }
  };

  const files = accepted.map((file) => (
    <li key={file.path}>
      <span className="file-name">
        {file.path}
      </span>
      <span className="error-icon without-errors">
        <Button
          type="button"
          text={<FontAwesomeIcon icon={faTimes} />}
          variant="link"
          className="remove-item"
          handleBtnClick={handleRemoveFile(file, true)}
        />
      </span>
    </li>
  ));

  const fileRejectionItems = rejected.map(({ file, errors }) => (
    <li key={file.path}>
      <span className="file-name">
        {file.path}
      </span>
      <span className="error-icon">
        <FontAwesomeIcon icon={faExclamationCircle} />
        <Button
          type="button"
          text={<FontAwesomeIcon icon={faTimes} />}
          variant="link"
          className="remove-item"
          handleBtnClick={handleRemoveFile(file)}
        />
      </span>
      <ul className="file-error">
        {errors.map((e) => (
          <li key={e.code}>
            {e.message}
          </li>
        ))}
      </ul>
    </li>
  ));

  return (
    <>
      <div className={`app-input ${className}`}>
        <Label htmlFor={id} label={label} labelClassName={labelClassName} />
        {minorLabel ? <span className="minor-title">{minorLabel}</span> : null}
        <div {...getRootProps({ className: 'dropzone' })}>
          <input id={id} data-testid={fieldTestId} {...getInputProps()} />
          <p>{fieldPlaceholder}</p>
        </div>
        {hint ? <small className="hint">{hint}</small> : null}
        <FormikErrorMessage fieldName={[prefix, fieldName].filter(Boolean).join('.')} />
        { icon }
      </div>
      <div className="upload-style">
        <ul>{files}</ul>
        <ul>{fileRejectionItems}</ul>
      </div>
    </>
  );
};

DragDropFileInput.propTypes = {
  id: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  isMulti: PropTypes.bool,
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  fieldPlaceholder: PropTypes.node,
  fieldName: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  minorLabel: PropTypes.node,
  hint: PropTypes.node,
  prefix: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  minSize: PropTypes.instanceOf(FileSize),
  maxSize: PropTypes.instanceOf(FileSize),
  fieldTestId: PropTypes.string,
};

DragDropFileInput.defaultProps = {
  setFieldValue: () => { },
  isMulti: false,
  labelClassName: null,
  label: null,
  fieldPlaceholder: null,
  fieldName: null,
  className: null,
  icon: null,
  minorLabel: null,
  hint: null,
  prefix: '',
  accept: [],
  maxFiles: 5,
  minSize: new FileSize(5, 'KB'),
  maxSize: new FileSize(10, 'MB'),
  fieldTestId: null,
};

export default DragDropFileInput;
