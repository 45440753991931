import graphql from 'babel-plugin-relay/macro';

const CityQuery = graphql`
    query CityQuery($countryAlpha2: String!) {
        cities(countryAlpha2: $countryAlpha2){
            name
        }
    }
`;

export default CityQuery;
