import { Trans, withTranslation } from 'react-i18next';
import './FooterStyle.scss';

const Footer = () => (
  <div className="app-footer">
    <Trans i18nKey="footer" count={new Date().getFullYear()}>
      <span className="px-1" />
    </Trans>
  </div>
);
export default withTranslation(['translation'])(Footer);
