/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import './CheckboxStyle.scss';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';
import FormikCheckbox from './FormikCheckbox/FormikCheckbox';

const FormikCheckboxes = (props) => {
  const {
    checkboxItems,
    className,
    fieldName,
    title,
  } = props;
  return (
    <div className={`checkbox-container ${className}`} title={title}>
      {
        checkboxItems.map((item) => (
          <FormikCheckbox
            key={item.key}
            fieldName={fieldName}
            {...item}
          />
        ))
      }
      <FormikErrorMessage fieldName={fieldName} />
    </div>
  );
};

FormikCheckboxes.propTypes = {
  className: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  checkboxItems: PropTypes.arrayOf(PropTypes.shape(FormikCheckbox.propTypes)).isRequired,
  title: PropTypes.string,
};

FormikCheckboxes.defaultProps = {
  className: null,
  title: null,
};
export default FormikCheckboxes;
