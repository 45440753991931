import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './CustomLinkStyle.scss';

const CustomLink = (props) => {
  const {
    to,
    className,
    disabledLink,
    text,
    onClick,
    target,
    dataTestId,
  } = props;
  return (
    <span className={`custom-link ${className}`}>
      <Link
        to={to}
        disabled={disabledLink}
        onClick={onClick}
        target={target}
        data-testid={dataTestId}
      >
        {text}
      </Link>
    </span>
  );
};

CustomLink.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  disabledLink: PropTypes.bool,
  onClick: PropTypes.func,
  text: PropTypes.node.isRequired,
  target: PropTypes.string,
  dataTestId: PropTypes.string,
};

CustomLink.defaultProps = {
  to: '#',
  className: null,
  disabledLink: false,
  onClick: null,
  target: null,
  dataTestId: '',
};
export default CustomLink;
