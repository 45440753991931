const uniqueArrayOfObjects = (array, uniqueKey) => (
  array.filter((value, index, self) => index === self.findIndex((t) => (
    t.place === value.place && t[uniqueKey] === value[uniqueKey]
  )))
);

const randomPick = (items) => items[Math.floor(Math.random() * items.length)];

export {
  uniqueArrayOfObjects,
  randomPick,
};
