import React, {
  useState, useEffect, useContext, createContext, useRef,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Errors from './ErrorCodes';
import Error404Page from './Error404';
import Error500Page from './Error500';
import AuthContext from '../../contexts/AuthContext';

const errorPages = {
  [Errors.NOT_FOUND_ERROR]: Error404Page,
  [Errors.AUTHNETICATION_ERROR]: Error404Page,
  [Errors.BAD_REQUEST_ERROR]: Error500Page,
  [Errors.SUSPENDED_ERROR]: Error404Page,
  [Errors.SERVER_ERROR]: Error500Page,
};

export const SetErrorContext = createContext();

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => { ref.current = value; });

  return ref.current;
};

export default function ErrorPageHandler({ children }) {
  const [error, setError] = useState();
  const ErrorPageComponent = errorPages[error];
  const location = useLocation();
  const prevLocation = usePrevious(location);
  const navigate = useNavigate();
  const { logoutUser } = useContext(AuthContext);
  const handleSuspendedError = () => {
    if (error === Errors.SUSPENDED_ERROR || error === Errors.AUTHNETICATION_ERROR) {
      logoutUser();
      navigate('/');
    }
  };
  useEffect(() => {
    if (error && location !== prevLocation) setError(undefined); // clear error
    handleSuspendedError();
  }, [error, setError, location]);

  if (!error) {
    return (
      <SetErrorContext.Provider value={setError}>
        {children}
      </SetErrorContext.Provider>
    );
  }

  return <ErrorPageComponent />;
}

export const useError = () => useContext(SetErrorContext);

ErrorPageHandler.propTypes = {
  children: PropTypes.node.isRequired,
};
