import graphql from 'babel-plugin-relay/macro';

const LoginMutation = graphql`
  mutation LoginMutation($input:LoginInput!) {
    login(input:$input){
      authToken
      authRefreshToken
      authRefreshTokenExpiry
      user{
        dbId
        name
        type
        unverifiedJobsCount
        totalJobsCount
        radiologistProfile {
          ...RadiologistProfileFragment_radiologistProfile
        }
      }
    }
  }
`;

export default LoginMutation;
