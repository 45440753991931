import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';
import Label from '../Label/Label';
import '../FormikInput/InputStyle.scss';

const FileInput = ({
  id, setFieldValue, isMulti, label, prefix, accept,
  labelClassName, fieldName, fieldPlaceholder, fieldTestId,
  className, icon, minorLabel, hint,
}) => {
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  useEffect(() => {
    setFieldValue({ [fieldName]: isMulti ? acceptedFiles : acceptedFiles[0] });
  }, [acceptedFiles]);

  const handleFiles = (event) => {
    setAcceptedFiles(event.currentTarget.files);
  };

  return (
    <div className={`app-input ${className}`}>
      <Label htmlFor={id} label={label} labelClassName={labelClassName} />
      { minorLabel ? <span className="minor-title">{minorLabel}</span> : null}
      <input
        id={id}
        type="file"
        onChange={handleFiles}
        placeholder={fieldPlaceholder}
        accept={accept.join(', ')}
        data-testid={fieldTestId}
        multiple={isMulti}
      />
      {hint ? <small className="hint">{hint}</small> : null}
      <FormikErrorMessage fieldName={[prefix, fieldName].filter(Boolean).join('.')} />
      { icon }
    </div>
  );
};

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  isMulti: PropTypes.bool,
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  fieldPlaceholder: PropTypes.string,
  fieldName: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  minorLabel: PropTypes.node,
  hint: PropTypes.string,
  prefix: PropTypes.string,
  fieldTestId: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string),
};

FileInput.defaultProps = {
  setFieldValue: () => {},
  isMulti: false,
  labelClassName: null,
  label: null,
  fieldPlaceholder: null,
  fieldName: null,
  className: null,
  icon: null,
  minorLabel: null,
  hint: '',
  prefix: '',
  fieldTestId: '',
  accept: [''],
};

export default FileInput;
