/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditProfileQueryVariables = {||};
export type EditProfileQueryResponse = {|
  +nationalities: $ReadOnlyArray<{|
    +nationality: string
  |}>,
  +countries: $ReadOnlyArray<{|
    +name: string,
    +alpha2: string,
  |}>,
  +specialities: $ReadOnlyArray<{|
    +id: string,
    +dbId: number,
    +name: ?string,
    +examTypes: $ReadOnlyArray<{|
      +id: string,
      +dbId: number,
      +name: ?string,
    |}>,
  |}>,
|};
export type EditProfileQuery = {|
  variables: EditProfileQueryVariables,
  response: EditProfileQueryResponse,
|};
*/


/*
query EditProfileQuery {
  nationalities {
    nationality
    id
  }
  countries {
    name
    alpha2
    id
  }
  specialities {
    id
    dbId
    name
    examTypes {
      id
      dbId
      name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nationality",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alpha2",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dbId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Speciality",
  "kind": "LinkedField",
  "name": "specialities",
  "plural": true,
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ExamType",
      "kind": "LinkedField",
      "name": "examTypes",
      "plural": true,
      "selections": [
        (v3/*: any*/),
        (v4/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Nationality",
        "kind": "LinkedField",
        "name": "nationalities",
        "plural": true,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "countries",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EditProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Nationality",
        "kind": "LinkedField",
        "name": "nationalities",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Country",
        "kind": "LinkedField",
        "name": "countries",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      (v5/*: any*/)
    ]
  },
  "params": {
    "cacheID": "4f12ce45041f870bdb4df629bff5ff6b",
    "id": null,
    "metadata": {},
    "name": "EditProfileQuery",
    "operationKind": "query",
    "text": "query EditProfileQuery {\n  nationalities {\n    nationality\n    id\n  }\n  countries {\n    name\n    alpha2\n    id\n  }\n  specialities {\n    id\n    dbId\n    name\n    examTypes {\n      id\n      dbId\n      name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f46555400cde0f8d8e547065922065bb';

module.exports = node;
