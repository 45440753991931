import React, { useContext } from 'react';
import Footer from '../Shared/Footer/Footer';
import Navbar from '../Shared/Navbar/Navbar';
import AppRoute from '../../Routes/AppRoutes';
import AuthContext from '../../contexts/AuthContext';
import AlertContext, { AlertProvider } from '../../contexts/AlertContext';
import FlashMsg from '../Shared/FlashMsg/FlashMsg';
import useOnlineStatus from '../../utils/useOnlineStatus';

const MainLayout = () => {
  const content = <AppRoute />;
  const { isAuthenticated } = useContext(AuthContext);

  useOnlineStatus();

  return (
    <AlertProvider>
      <AlertContext.Consumer>
        {({ alert }) => (
          isAuthenticated
            ? (
              <>
                <Navbar />
                <div className="main-content">
                  <FlashMsg
                    title={alert}
                    isDisplayed={alert !== undefined}
                    variant="success"
                  />
                  {content}
                </div>
                <Footer />
              </>
            )
            : content
        )}
      </AlertContext.Consumer>
    </AlertProvider>
  );
};

export default MainLayout;
