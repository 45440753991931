const getFilePath = (file) => file.path || file.file?.path;

const difference = (filesArray1, filesArray2) => {
  const filesArray2Paths = filesArray2.map((file) => getFilePath(file));
  return filesArray1.filter((file) => !filesArray2Paths.includes(getFilePath(file)));
};

export {
  getFilePath,
  difference,
};
