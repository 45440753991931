import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ALERT_DURATION } from '../utils/constants';

const AlertContext = createContext();
export default AlertContext;

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(undefined);

  const hideAlert = () => {
    setAlert(undefined);
  };

  const showAlert = (msg) => {
    setAlert(msg);
  };

  const contextData = {
    alert,
    showAlert,
    hideAlert,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      hideAlert();
    }, ALERT_DURATION);
    return () => clearInterval(interval);
  }, [alert]);

  return (
    <AlertContext.Provider value={contextData}>
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
