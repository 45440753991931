/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ViewRadProfileQueryFragment$ref = any;
export type UpdateRadiologistProfileInput = {|
  city?: ?string,
  clientMutationId?: ?string,
  country?: ?string,
  dateOfBirth?: ?string,
  email?: ?string,
  gender?: ?string,
  name?: ?string,
  nationality?: ?string,
  personalId?: ?any,
  phone?: ?string,
  practiceSince?: ?number,
  professionalLicenses?: ?$ReadOnlyArray<any>,
  qualificationCategory?: ?string,
  qualificationCertificates?: ?$ReadOnlyArray<any>,
  street?: ?string,
  telegramEnabled?: ?boolean,
|};
export type UpdateProfileMutationVariables = {|
  input: UpdateRadiologistProfileInput
|};
export type UpdateProfileMutationResponse = {|
  +updateRadiologistProfile: ?{|
    +radiologist: ?{|
      +$fragmentRefs: ViewRadProfileQueryFragment$ref
    |},
    +errors: any,
  |}
|};
export type UpdateProfileMutation = {|
  variables: UpdateProfileMutationVariables,
  response: UpdateProfileMutationResponse,
|};
*/


/*
mutation UpdateProfileMutation(
  $input: UpdateRadiologistProfileInput!
) {
  updateRadiologistProfile(input: $input) {
    radiologist {
      ...ViewRadProfileQueryFragment
      id
    }
    errors
  }
}

fragment ViewRadProfileQueryFragment on Radiologist {
  id
  name
  phone
  email
  telegramEnabled
  activeContract {
    dbId
    id
  }
  radiologistProfile {
    dateOfBirth
    gender
    nationality
    street
    country
    city
    personalId
    professionalLicenses
    qualificationCertificates
    qualificationCategory
    practiceSince
    examTypes {
      name
      speciality {
        name
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "errors",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRadiologistProfilePayload",
        "kind": "LinkedField",
        "name": "updateRadiologistProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Radiologist",
            "kind": "LinkedField",
            "name": "radiologist",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ViewRadProfileQueryFragment"
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateRadiologistProfilePayload",
        "kind": "LinkedField",
        "name": "updateRadiologistProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Radiologist",
            "kind": "LinkedField",
            "name": "radiologist",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "telegramEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contract",
                "kind": "LinkedField",
                "name": "activeContract",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dbId",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RadiologistProfile",
                "kind": "LinkedField",
                "name": "radiologistProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dateOfBirth",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gender",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nationality",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "country",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personalId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "professionalLicenses",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qualificationCertificates",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qualificationCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "practiceSince",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExamType",
                    "kind": "LinkedField",
                    "name": "examTypes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Speciality",
                        "kind": "LinkedField",
                        "name": "speciality",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d89ca557a90785fb9e0b1f93c5b1f81",
    "id": null,
    "metadata": {},
    "name": "UpdateProfileMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProfileMutation(\n  $input: UpdateRadiologistProfileInput!\n) {\n  updateRadiologistProfile(input: $input) {\n    radiologist {\n      ...ViewRadProfileQueryFragment\n      id\n    }\n    errors\n  }\n}\n\nfragment ViewRadProfileQueryFragment on Radiologist {\n  id\n  name\n  phone\n  email\n  telegramEnabled\n  activeContract {\n    dbId\n    id\n  }\n  radiologistProfile {\n    dateOfBirth\n    gender\n    nationality\n    street\n    country\n    city\n    personalId\n    professionalLicenses\n    qualificationCertificates\n    qualificationCategory\n    practiceSince\n    examTypes {\n      name\n      speciality {\n        name\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '705e2c52a1942dd43658cebaa7702996';

module.exports = node;
