import React, { useContext, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthContext from '../../../contexts/AuthContext';
import defaultImg from '../../../assets/images/user.svg';
import RadiologistMenuLinks from './RadiologistMenuLinks';

const NavbarDropdown = () => {
  const [expandDropdownMenu, setExpandDropdownMenu] = useState();
  const collapseDropdownMenu = () => {
    setExpandDropdownMenu(!expandDropdownMenu);
  };
  const {
    currentUser,
    logoutUser,
    isRadiologist,
  } = useContext(AuthContext);
  const { t } = useTranslation('translation');
  const userLinks = isRadiologist() ? RadiologistMenuLinks() : [];
  const userMenuLinks = [
    ...userLinks,
    {
      to: '/contract',
      text: t('navbar.contract'),
      key: 'contract',
    },
    {
      to: '/job-requests',
      text: t('navbar.jobRequests'),
      key: '3',
      onClick: () => {
        collapseDropdownMenu();
      },
    },
    {
      to: '/',
      text: t('navbar.logout'),
      key: '4',
      onClick: (e) => {
        e.preventDefault();
        logoutUser();
        collapseDropdownMenu();
      },
    },
  ];

  return (
    <div>
      <NavDropdown
        show={expandDropdownMenu}
        className="navbar-dd white"
        title={
          (
            <span>
              <img
                src={defaultImg}
                alt={currentUser.name}
              />
              <div className="user-data">
                <span className="user-name">{currentUser.name}</span>
                <span className="user-title">{currentUser.type}</span>
              </div>

              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          )
        }
        id="collasible-nav-dropdown"
        onClick={collapseDropdownMenu}
      >
        {
          userMenuLinks.map((link) => (
            <NavLink
              key={link.key}
              to={link.to}
              onClick={link.onClick}
            >
              {link.text}
            </NavLink>
          ))
        }
      </NavDropdown>
    </div>
  );
};

export default NavbarDropdown;
