import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from 'prop-types';
import './HorizontalAccordionStyle.scss';

const HorizontalAccordion = ({
  defaultActiveKey,
  accordionItems,
  className,
}) => (
  <div className={` h-accordion ${className} `}>
    <Accordion defaultActiveKey={defaultActiveKey}>
      {
        accordionItems.map((item) => (
          <Accordion.Item eventKey={item.eventKey} key={item.eventKey}>
            <Accordion.Header>{item.header}</Accordion.Header>
            <Accordion.Body>
              {item.content}
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  </div>
);

HorizontalAccordion.propTypes = {
  defaultActiveKey: PropTypes.string,
  className: PropTypes.string,
  accordionItems: PropTypes.arrayOf(
    PropTypes.shape({
      eventKey: PropTypes.string.isRequired,
      header: PropTypes.node.isRequired,
      content: PropTypes.node.isRequired,
    }),
  ).isRequired,
};

HorizontalAccordion.defaultProps = {
  defaultActiveKey: '',
  className: '',
};

export default HorizontalAccordion;
