import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation('translation');

  return [{
    to: '/profile',
    text: t('navbar.profile'),
    key: 'profile',
  }];
};
