/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CityQueryVariables = {|
  countryAlpha2: string
|};
export type CityQueryResponse = {|
  +cities: $ReadOnlyArray<{|
    +name: string
  |}>
|};
export type CityQuery = {|
  variables: CityQueryVariables,
  response: CityQueryResponse,
|};
*/


/*
query CityQuery(
  $countryAlpha2: String!
) {
  cities(countryAlpha2: $countryAlpha2) {
    name
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "countryAlpha2"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "countryAlpha2",
    "variableName": "countryAlpha2"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "City",
        "kind": "LinkedField",
        "name": "cities",
        "plural": true,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "City",
        "kind": "LinkedField",
        "name": "cities",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3da96e4f4a780e2d6cd132fb76437aa9",
    "id": null,
    "metadata": {},
    "name": "CityQuery",
    "operationKind": "query",
    "text": "query CityQuery(\n  $countryAlpha2: String!\n) {\n  cities(countryAlpha2: $countryAlpha2) {\n    name\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3e4d7695acf035846546b5cc8f2b2969';

module.exports = node;
