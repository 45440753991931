import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../contexts/AuthContext';
import Error404 from '../components/Errors/Error404';

const PublicRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);

  if (isAuthenticated) {
    return <Error404 />;
  }
  return children;
};

export default PublicRoute;

PublicRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
