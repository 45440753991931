import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { useTranslation } from 'react-i18next';
import NavbarDropdown from './NavbarDropdown';
import './NavbarStyle.scss';

import logo from '../../../assets/images/logo.png';

const AppNavbar = () => {
  const { t } = useTranslation();
  return (
    <div className="app-navbar">
      <Container>
        <Navbar variant="light" bg="white" expand="lg">
          <Navbar.Brand to="/" as={Link}>
            <img src={logo} alt={t('expertI')} height={60} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="expert-i-navbar-collapse" />
          <Navbar.Collapse id="expert-i-navbar-collapse" className="justify-content-end">
            <Nav>
              <NavbarDropdown />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </div>
  );
};
export default AppNavbar;
