import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import './SelectStyle.scss';
import Label from '../Label/Label';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';

const FormikSelect = (props) => {
  const {
    label,
    labelClassName,
    id,
    fieldName,
    className,
    options,
    onChangeSelect,
  } = props;

  return (
    <div className={className}>
      <Label htmlFor={id} label={label} labelClassName={labelClassName} />
      <Field id={id} as="select" name={fieldName} onChange={onChangeSelect}>
        <option value="" defaultValue>Select</option>
        {options.map(({ key, value }) => (
          <option
            key={`${fieldName}-${key}-${value}`}
            value={key}
          >
            {value}
          </option>
        ))}
      </Field>
      <FormikErrorMessage fieldName={fieldName} />
    </div>
  );
};

FormikSelect.propTypes = {
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  id: PropTypes.string.isRequired,
  fieldName: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  onChangeSelect: PropTypes.func,
};
FormikSelect.defaultProps = {
  labelClassName: null,
  label: null,
  fieldName: null,
  className: null,
  onChangeSelect: null,
};
export default FormikSelect;
