const formHelpers = {
  // reformat select option key, value
  reformatSelectOptions: (data, keyName, valueName) => {
    let options;
    /*
    If both (key, value) names provided
      then mapping on data and select only keyName as key and value as value
    If only key provided
      then key and value are the same
     */
    if (keyName && valueName) {
      options = data?.map((instance) => ({ key: instance[keyName], value: instance[valueName] }));
    } else if (keyName) {
      options = data?.map((instance) => ({ key: instance[keyName], value: instance[keyName] }));
    } else {
      options = data?.map((instance) => ({ key: instance, value: instance }));
    }
    return options ?? [];
  },
};

module.exports = formHelpers;
