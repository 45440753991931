import React from 'react';
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import userImg from '../../assets/images/userImg.png';
import './ProfileCardStyle.scss';

const ProfileCard = ({ user }) => (
  <div className="profile-card main-card make-it-top text-center pb-5 mb-4">
    <div className="userImg rounded-circle m-auto mb-3">
      <Image src={userImg} className="rounded-circle" fluid />
    </div>
    <p className="username text-capitalize mb-1">{user?.name}</p>
    <p className="mb-5 user-email">
      <FontAwesomeIcon icon={faEnvelope} className="me-2" />
      {user?.email}
    </p>
  </div>
);

ProfileCard.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProfileCard;
