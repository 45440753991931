const units = ['B', 'KB', 'MB', 'GB'];

class FileSize {
  constructor(size, unit) {
    this.size = size;
    this.unit = unit;
    this.index = units.findIndex((u) => u === unit);

    if (this.index < 0) {
      throw new Error(`Invalid unit '${unit}', allowed units are ${units}`);
    }
  }

  sizeInBytes() {
    return this.size * 1024 ** this.index;
  }
}

export default FileSize;
