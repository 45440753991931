import graphql from 'babel-plugin-relay/macro';

const RefreshTokenMutation = graphql`
  mutation RefreshTokenMutation($input: RefreshTokenInput!){
    refreshToken(input:$input){
      authToken
      authRefreshToken
      authRefreshTokenExpiry
      user{
        dbId
        name
        type
        radiologistProfile {
          ...RadiologistProfileFragment_radiologistProfile
        }
      }
    }
  }
`;

export default RefreshTokenMutation;
