import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import PropTypes from 'prop-types';
import './AccordionStyle.scss';

const AppAccordion = ({
  defaultActiveKey,
  eventKey,
  accordionHeader,
  accordionContent,
}) => (
  <div className="app-accordion">
    <Accordion defaultActiveKey={eventKey || defaultActiveKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>{accordionHeader}</Accordion.Header>
        <Accordion.Body>
          {accordionContent}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>
);

AppAccordion.propTypes = {
  defaultActiveKey: PropTypes.string,
  eventKey: PropTypes.string.isRequired,
  accordionHeader: PropTypes.node.isRequired,
  accordionContent: PropTypes.node.isRequired,
};

AppAccordion.defaultProps = {
  defaultActiveKey: '',
};

export default AppAccordion;
