import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import './TitleStyle.scss';

const Title = (props) => {
  const {
    title,
    className,
    tags,
    childComp,
  } = props;
  return (
    <div
      className={`app-title ${className}`}
    >
      <Container className="d-flex align-items-center justify-content-between">
        <h2>
          {title}
          {tags}
        </h2>
        { childComp || null}
      </Container>
    </div>
  );
};
Title.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  tags: PropTypes.node,
  childComp: PropTypes.node,
};
Title.defaultProps = {
  className: '',
  tags: <></>,
  childComp: null,
};

export default Title;
