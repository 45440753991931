const validationMessages = {
  // General Required Field
  requiredField: () => ({ key: 'requiredField' }),

  // General Invalid
  invalid: (name) => ({ key: 'invalid', values: { name } }),

  // Numbers
  minNumber: (count) => ({ key: 'minNumber', values: { count } }),
  maxNumber: (count) => ({ key: 'maxNumber', values: { count } }),
  invalidNumber: () => ({ key: 'invalidNumber' }),

  // Characters
  min1Char: () => ({ key: 'minChar' }),
  minChars: (count) => ({ key: 'minChars', values: { count } }),

  // Selects
  min1Select: () => ({ key: 'min1Select' }),
  minSelect: (count) => ({ key: 'minSelect', values: { count } }),

  // Dates
  dateNotInFuture: () => ({ key: 'dateNotInFuture' }),

  // Email
  invalidEmail: () => ({ key: 'invalidEmail' }),

  // Files
  smFile: (size, unit) => ({ key: 'smFile', values: { size, unit } }),
  lgFile: (size, unit) => ({ key: 'lgFile', values: { size, unit } }),
  typeFile: (types) => ({ key: 'typeFile', values: { types } }),
  fileHasErrors: () => ({ key: 'fileHasErrors' }),
  filesHasErrors: () => ({ key: 'filesHasErrors' }),
};

export default validationMessages;
