import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../contexts/AuthContext';
import Error404 from '../components/Errors/Error404';

const MedicalFacilityRoute = ({ children }) => {
  const { isMedicalFacility } = useContext(AuthContext);

  if (isMedicalFacility()) {
    return children;
  }

  return <Error404 />;
};

export default MedicalFacilityRoute;

MedicalFacilityRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
