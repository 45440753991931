import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../contexts/AuthContext';
import Error404 from '../components/Errors/Error404';

const RadiologistRoute = ({ children }) => {
  const { isRadiologist } = useContext(AuthContext);

  if (isRadiologist()) {
    return children;
  }

  return <Error404 />;
};

export default RadiologistRoute;

RadiologistRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
