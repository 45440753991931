import { useTranslation } from 'react-i18next';
import './ErrorsStyle.scss';
import Text500 from '../../assets/images/500Text.png';

const Error500 = () => {
  const { t } = useTranslation('errorPages');

  return (
    <div className="error-page error-500">
      <div className="page-text justify-content-center">
        <img src={Text500} alt={t('errorPages:error500')} />
        <h1>{t('errorPages:error500')}</h1>
        <p>{t('errorPages:error500Notice')}</p>
        <a href="/" className="primary-link">{t('errorPages:goToHomepage')}</a>
      </div>
    </div>
  );
};

export default Error500;
