import { createContext } from 'react';
import PropTypes from 'prop-types';
import actionCable from 'actioncable';

const CableApp = {};
CableApp.cable = actionCable.createConsumer(process.env.REACT_APP_WEBSOCKET_BACKEND_URL);

const ActionCableContext = createContext(CableApp);
export default ActionCableContext;

export const ActionCableProvider = ({ children }) => (
  <ActionCableContext.Provider value={CableApp}>
    {children}
  </ActionCableContext.Provider>
);

ActionCableProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
