import { useContext } from 'react';
import PropTypes from 'prop-types';
import AuthContext from '../contexts/AuthContext';
import Login from '../components/Login/Login';
import EditRadiologistProfile from '../components/EditProfile/EditRadiologistProfile';

const ProtectedRoute = ({ children }) => {
  const {
    isAuthenticated,
    completedProfile,
    isRadiologist,
  } = useContext(AuthContext);

  if (isAuthenticated && isRadiologist() && !completedProfile()) {
    return <EditRadiologistProfile />;
  }
  if (isAuthenticated) {
    return children;
  }
  return <Login />;
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
