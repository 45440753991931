const toCamelCase = (str) => (
  str.replace(/^\w|[A-Z]|\b\w/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase())).replace(/\s+/g, '')
);
const fileNameFromURL = (url) => (
  decodeURI(url).replace(/[#?].*$/, '').split('/').pop()
);
const includesAny = (string, ...words) => words.some((word) => string.includes(word));

const generateRandomString = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const capitalizeFirstLetterOnly = (str) => (
  str.charAt(0).toUpperCase() + str.slice(1)
);

const capitalizeAllWords = (str) => {
  const arr = str.split(' ');
  arr.forEach((word, index) => {
    arr[index] = arr[index].charAt(0).toUpperCase() + arr[index].slice(1);
  });
  return arr.join(' ');
};

const removeUnderScore = (str) => (
  str.replace(/_/g, ' ')
);

export {
  toCamelCase,
  fileNameFromURL,
  includesAny,
  generateRandomString,
  capitalizeFirstLetterOnly,
  capitalizeAllWords,
  removeUnderScore,
};
