/* eslint-disable react/jsx-props-no-spreading */
import { Field } from 'formik';
import PropTypes from 'prop-types';

const FormikCheckbox = ({
  fieldFor,
  labelClassName,
  disabled,
  fieldName,
  fieldValue,
  onChange,
  fieldText,
}) => (
  <label
    htmlFor={fieldFor}
    className={` ${labelClassName} ${disabled ? 'disabled' : ''}`}
  >
    <Field
      type="checkbox"
      name={fieldName}
      value={fieldValue}
      id={fieldFor}
      disabled={disabled}
      {...(onChange ? { onChange } : {})}
    />
    {fieldText}
  </label>
);

FormikCheckbox.propTypes = {
  labelClassName: PropTypes.string,
  fieldText: PropTypes.string.isRequired,
  fieldValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  disabled: PropTypes.bool,
  fieldFor: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

FormikCheckbox.defaultProps = {
  onChange: null,
  disabled: false,
  labelClassName: '',
  fieldValue: undefined,
};

export default FormikCheckbox;
