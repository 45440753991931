import { useEffect, useContext } from 'react';
import AuthContext from '../contexts/AuthContext';
import ActionCableContext from '../contexts/ActionCableContext';

export default function useOnlineStatus() {
  const { isAuthenticated, getAuthToken, isRadiologist } = useContext(AuthContext);
  const cableApp = useContext(ActionCableContext);

  useEffect(() => {
    if (isAuthenticated && isRadiologist()) {
      const channel = cableApp.cable.subscriptions.create(
        {
          channel: 'RadiologistAppearanceChannel',
          token: getAuthToken(),
        },
        {
          received: () => { },
        },
      );

      return () => {
        channel.unsubscribe();
      };
    }

    return null;
  }, [isAuthenticated]);
}
