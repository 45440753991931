import {
  Environment, Network, RecordSource, Store,
} from 'relay-runtime';
import axios from 'axios';
import { AUTH_TOKEN_KEY } from './utils/constants';
import eventBus from './EventBus';

export const relayStore = new RecordSource();

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
async function fetchRelay(params, variables, cacheConfig, uploadables) {
  // In case of debugging you can uncomment the next line
  // console.log(`fetching query ${params.name} with ${JSON.stringify(variables)}`);

  const token = relayStore.get(AUTH_TOKEN_KEY);
  const { REACT_APP_GRAPHQL_URL } = process.env;

  const { text, operationKind } = params;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const request = {
    method: 'POST',
    headers,
  };

  let formData;

  if (uploadables) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.');
    }

    formData = new FormData();

    formData.append('query', text);
    formData.append('variables', JSON.stringify(variables));

    /**
     * Append uploadables to formData,
     * For the following uploadables object:
     *   uploadables: {
     *     attachments: [attachmentFile1, attachmentFile2],
     *     avatar: avatarFile
     *   }
     * the following will be appended:
     *   uploadables[attachments][] = attachmentFile1
     *   uploadables[attachments][] = attachmentFile2
     *   uploadables[avatar] = avatarFile
     */
    Object.entries(uploadables).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(`uploadables[${key}][]`, item);
        });
      } else {
        formData.append(`uploadables[${key}]`, value);
      }
    });

    request.body = formData;
  } else {
    request.headers['Content-Type'] = 'application/json';
    request.body = JSON.stringify({
      query: text,
      variables,
    });
  }

  const options = {
    url: REACT_APP_GRAPHQL_URL,
    method: 'POST',
    headers: request.headers,
    data: formData !== undefined ? formData : {
      query: text,
      variables,
    },
    onUploadProgress(progressEvent) {
      const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      eventBus.dispatch('uploadProgress', { percentage });
    },
  };

  const responseJson = await axios(options)
    .then((response) => response.data);

  if (operationKind === 'mutation' && responseJson.errors) {
    throw responseJson.errors[0];
  }
  return responseJson;
}

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(relayStore),
});
