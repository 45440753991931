import Lottie from 'lottie-react';
import './LoaderStyle.scss';
import loaderAnimation from './e fade.json';

const Loader = () => (
  <div className="app-loader">
    <Lottie
      autoplay
      loop
      animationData={loaderAnimation}
    />
  </div>
);

export default Loader;
