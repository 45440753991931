import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import './FlashMsgStyle.scss';

const FlashMsg = (props) => {
  const {
    variant,
    errors,
    className,
    title,
    isDisplayed,
    errorMessage,
    skipFields,
  } = props;
  if (!isDisplayed) { return ''; }
  return (
    <Alert
      variant={variant}
      className={className}
    >
      {
        errorMessage
          ? (
            <p>{errorMessage}</p>
          )
          : (
            <>
              {
              title && <Alert.Heading>{title}</Alert.Heading>
            }
              { errors.length > 0 && (
              <ul>
                {
                  errors.map((error) => (
                    <li key={error.field}>
                      { skipFields ? error.message : `${error.field} : ${error.message}` }
                    </li>
                  ))
                }
              </ul>
              )}
            </>
          )
      }
    </Alert>
  );
};
FlashMsg.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  ),
  title: PropTypes.node,
  isDisplayed: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  skipFields: PropTypes.bool,
};

FlashMsg.defaultProps = {
  className: '',
  title: '',
  errorMessage: '',
  errors: [],
  skipFields: false,
};

export default FlashMsg;
