import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLazyLoadQuery, useRelayEnvironment, useMutation } from 'react-relay/hooks';
import { fetchQuery } from 'react-relay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import AppAccordion from '../Shared/Accordion/AppAccordion';
import FormikSelect from '../Shared/Form/FormikSelect/FormikSelect';
import FormikInput from '../Shared/Form/FormikInput/FormikInput';
import FormikTextarea from '../Shared/Form/FormikTextarea/FormikTextarea';
import Button from '../Shared/Button/Button';
import FormikRadioButton from '../Shared/Form/FormikRadioButton/FormikRadioButton';
import ValidationSchema from './ValidationSchema';
import CityQuery from './CityQuery/CityQuery';
import { reformatSelectOptions } from '../../utils/forms';
import { toDate } from '../../utils/dateTime';
import { uniqueArrayOfObjects } from '../../utils/arrays';
import HorizontalAccordion from '../Shared/HorizontalAccordion/HorizontalAccordion';
import FormikCheckboxes from '../Shared/Form/FormikCheckboxes/FormikCheckboxes';
import UpdateProfileMutation from './ProfileMutation/UpdateProfileMutation';
import DragDropFileInput from '../Shared/Form/FileInput/DragDropFileInput';
import FileInput from '../Shared/Form/FileInput/FileInput';
import CreateProfileMutation from './ProfileMutation/CreateProfileMutation';
import { toCamelCase } from '../../utils/strings';
import EditProfileQuery from './EditProfileQuery/EditProfileQuery';
import Loader from '../Shared/Loader/Loader';
import FlashMsg from '../Shared/FlashMsg/FlashMsg';
import AlertContext from '../../contexts/AlertContext';
import AuthContext from '../../contexts/AuthContext';

const RadiologistProfileForm = ({ radiologist }) => {
  const { t } = useTranslation(['editProfile', 'translation', 'uploader']);
  const { setHasProfile } = useContext(AuthContext);
  const navigate = useNavigate();
  const environment = useRelayEnvironment();
  const {
    countries, nationalities, specialities,
  } = useLazyLoadQuery(EditProfileQuery);
  const newCountries = reformatSelectOptions(countries, 'alpha2', 'name');
  const filteredNationalities = uniqueArrayOfObjects(nationalities, 'nationality');
  const newNationalities = reformatSelectOptions(filteredNationalities, 'nationality');
  const { radiologistProfile } = radiologist;
  const existingExamTypeIds = radiologistProfile?.examTypeIds?.map(String) ?? [];
  const fetchCountryCities = ({ countryAlpha2, onStart, onNext }) => {
    fetchQuery(environment, CityQuery, { countryAlpha2 }).subscribe({
      start: onStart,
      next: onNext,
    });
  };
  const currentCountry = radiologistProfile?.country;
  const [cities, setCities] = useState(reformatSelectOptions(currentCountry?.cities, 'name'));
  const [errors, setErrors] = useState(null);
  const [commit, isInFlight] = useMutation(
    radiologistProfile ? UpdateProfileMutation : CreateProfileMutation,
  );

  const { showAlert } = useContext(AlertContext);

  // Update current country's cities
  useEffect(() => {
    if (!currentCountry) return;

    fetchCountryCities({
      countryAlpha2: currentCountry,
      onNext: (data) => {
        setCities(reformatSelectOptions(data.cities, 'name'));
      },
    });
  }, [currentCountry]);

  const onSubmit = (values) => {
    let uploadables = {};
    const input = { ...values, practiceSince: Number(values.practiceSince) };
    Object.entries(input.attachmentsAttributes).forEach(([key, value]) => {
      if (value instanceof File || (value instanceof Object && value.length > 0)) {
        uploadables[key] = value;
      }
    });
    if (!Object.keys(uploadables).length) uploadables = null;
    delete input.attachmentsAttributes;
    if (radiologistProfile) {
      delete input.examTypeIds;
    } else {
      input.examTypeIds = input.examTypeIds.map(Number).filter((elem) => !Number.isNaN(elem));
    }

    commit({
      variables: { input },
      uploadables,
      onCompleted: (data) => {
        const object = data[Object.keys(data)[0]];
        const dataErrors = object.errors.length ? object.errors : null;
        if (dataErrors) {
          setErrors(dataErrors);
        } else {
          setHasProfile(true);
          navigate('/profile');
          showAlert(radiologistProfile ? t('updatedSuccessfully') : t('createdSuccessfully'));
        }
      },
      onError: (responseErrors) => {
        const typeErrors = responseErrors.extensions?.problems;
        const formattedErrors = [];
        Object.values(typeErrors).forEach((fieldError) => {
          errors.push({ [fieldError.path[0]]: fieldError?.explanation });
        });
        setErrors(formattedErrors);
      },
    });
  };

  const mapSpecialitiesExamTypes = (examTypes, speciality, examTypeIds, setExamTypeIds) => {
    const specialityCamelCase = toCamelCase(speciality.name);
    const checkedExamTypeIds = speciality.examTypes.map((o) => String(o.dbId));
    const checkBoxes = [{
      fieldText: 'All',
      disabled: !!radiologistProfile,
      fieldFor: specialityCamelCase,
      key: `exam-${speciality.id}`,
      fieldValue: specialityCamelCase,
      onChange: (event) => {
        const { checked } = event.currentTarget;
        let modifiedExamTypeIds;
        if (checked) {
          modifiedExamTypeIds = [
            ...examTypeIds,
            ...checkedExamTypeIds,
            specialityCamelCase,
          ];
        } else {
          modifiedExamTypeIds = examTypeIds.filter(
            (id) => !checkedExamTypeIds.includes(id) && specialityCamelCase !== id,
          );
        }
        setExamTypeIds([...new Set(modifiedExamTypeIds)]);
      },
    }];
    examTypes.forEach((examType) => {
      checkBoxes.push({
        fieldText: examType.name,
        fieldFor: toCamelCase(examType.name),
        key: examType.id,
        disabled: !!radiologistProfile,
        fieldValue: String(examType.dbId),
        onChange: (event) => {
          const { checked, value } = event.currentTarget;
          const newExamTypeIds = (
            checked ? [...examTypeIds, value] : examTypeIds.filter((id) => value !== id)
          );
          const isSubset = checkedExamTypeIds.every((val) => newExamTypeIds.includes(val));
          let modifiedExamTypeIds;
          if (isSubset) {
            modifiedExamTypeIds = [...newExamTypeIds, specialityCamelCase];
          } else {
            modifiedExamTypeIds = newExamTypeIds.filter((id) => specialityCamelCase !== id);
          }
          setExamTypeIds([...new Set(modifiedExamTypeIds)]);
        },
      });
    });
    return checkBoxes;
  };

  const telegramCheckboxItems = [
    {
      fieldText: t('fields.telegramNotifications'),
      fieldName: 'telegramEnabled',
      key: 1,
      fieldFor: 'telegram',
      disabled: !radiologist.activeContract,
    },
  ];

  return (
    <Col lg={{ span: 9 }}>
      <div className="main-card make-it-top">
        { isInFlight && <Loader /> }
        <Formik
          initialValues={{
            name: radiologist?.name ?? '',
            dateOfBirth: toDate(radiologistProfile?.dateOfBirth) ?? '',
            phone: radiologist?.phone ?? '',
            email: radiologist?.email ?? '',
            telegramEnabled: radiologist?.telegramEnabled,
            nationality: radiologistProfile?.nationality ?? '',
            gender: radiologistProfile?.gender ?? '',
            street: radiologistProfile?.street ?? '',
            city: radiologistProfile?.city ?? '',
            country: radiologistProfile?.country ?? '',
            qualificationCategory: radiologistProfile?.qualificationCategory ?? '',
            practiceSince: radiologistProfile?.practiceSince ?? '',
            examTypeIds: existingExamTypeIds,
            attachmentsAttributes: {},
          }}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
          render={({
            values,
            setFieldValue,
            handleChange,
          }) => {
            const handleMaleChange = () => {
              setFieldValue('gender', 'male');
            };
            const handleFemaleChange = () => {
              setFieldValue('gender', 'female');
            };

            const genderItems = [
              {
                label: 'Male',
                id: 'male',
                radioValue: 'male',
                checked: values.gender === 'male',
                handleChange: handleMaleChange,
                key: 1,
              },
              {
                label: 'Female',
                id: 'female',
                radioValue: 'female',
                checked: values.gender === 'female',
                handleChange: handleFemaleChange,
                key: 2,
              },
            ];
            const handleConsultantChange = () => {
              setFieldValue('qualificationCategory', 'consultant');
            };
            const handleSpecialistChange = () => {
              setFieldValue('qualificationCategory', 'specialist');
            };

            const qualificationItems = [
              {
                label: 'Consultant',
                id: 'consultant',
                radioValue: 'consultant',
                checked: values.qualificationCategory === 'consultant',
                handleChange: handleConsultantChange,
                key: 1,
              },
              {
                label: 'Specialist',
                id: 'specialist',
                radioValue: 'specialist',
                checked: values.qualificationCategory === 'specialist',
                handleChange: handleSpecialistChange,
                key: 2,
              },
            ];
            const specialitiesCheckboxesItems = (() => specialities.map((speciality) => (
              {
                eventKey: speciality.id,
                header: speciality.name,
                content: (
                  <FormikCheckboxes
                    className="specialities-checkbox"
                    fieldName="examTypeIds"
                    checkboxItems={mapSpecialitiesExamTypes(
                      speciality.examTypes,
                      speciality,
                      values.examTypeIds,
                      (examTypeIds) => {
                        setFieldValue('examTypeIds', examTypeIds);
                      },
                    )}
                  />
                ),
              }
            )))();
            return (
              <Form data-testid="form">
                <Row className="mb-4">
                  <Col md={{ span: 12 }}>
                    <FlashMsg errors={errors} title={t('titles.serverErrors')} isDisplayed={!!errors} variant="danger" />
                  </Col>
                  <AppAccordion
                    defaultActiveKey="1"
                    accordionHeader={t('titles.basicInformation')}
                    eventKey="1"
                    accordionContent={(
                      <Row>
                        <Col md={{ span: 6 }}>
                          <FormikInput
                            id="name"
                            label={t('fields.fullName')}
                            labelClassName="required"
                            fieldPlaceholder={t('fields.fullName')}
                            fieldType="text"
                            fieldName="name"
                            className="mb-3"
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <FormikInput
                            id="dateOfBirth"
                            label={t('fields.DateOfBirth')}
                            labelClassName="required"
                            fieldType="date"
                            fieldName="dateOfBirth"
                            className="mb-3"
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <div className="mb-3">
                            <FormikRadioButton
                              title={t('fields.gender')}
                              titleClassName="required"
                              fieldName="gender"
                              className="title-label custom-radio w-100 two-radio"
                              radioBtnItems={genderItems}
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <FormikInput
                            id="phone"
                            label={t('fields.phoneNumber')}
                            labelClassName="required"
                            fieldType="text"
                            fieldPlaceholder={t('fields.phoneNumber')}
                            fieldName="phone"
                            className="mb-3"
                          />
                          <FormikCheckboxes
                            className="mb-4 d-inline-block"
                            fieldName="telegram"
                            checkboxItems={telegramCheckboxItems}
                            {...(!radiologist.activeContract ? { title: t('disabledReasons.telegramNotifications.inactiveContract') } : {})}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <FormikInput
                            id="email"
                            label={t('fields.email')}
                            labelClassName="required"
                            fieldType="email"
                            fieldName="email"
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <FormikSelect
                            fieldName="nationality"
                            id="nationality"
                            options={newNationalities}
                            labelClassName="required"
                            label={t('fields.nationality')}
                            onChangeSelect={handleChange}
                          />
                        </Col>
                      </Row>
                    )}
                  />
                  <AppAccordion
                    defaultActiveKey="2"
                    accordionHeader={t('titles.address')}
                    eventKey="2"
                    accordionContent={(
                      <Row>
                        <Col md={{ span: 6 }} className="mb-3">
                          <FormikSelect
                            fieldName="country"
                            id="country"
                            options={newCountries}
                            label={t('fields.country')}
                            labelClassName="required"
                            onChangeSelect={(e) => {
                              const countryAlpha2 = e.target.value;
                              fetchCountryCities({
                                countryAlpha2,
                                onStart: () => {
                                  setFieldValue('country', countryAlpha2);
                                  setFieldValue('city', '');
                                  setCities([]);
                                },
                                onNext: (data) => {
                                  setCities(reformatSelectOptions(data.cities, 'name'));
                                },
                              });
                            }}
                          />
                        </Col>
                        <Col md={{ span: 6 }} className="mb-3">
                          <FormikSelect
                            fieldName="city"
                            id="city"
                            options={cities}
                            label={t('fields.city')}
                            labelClassName="required"
                            onChangeSelect={handleChange}
                          />
                        </Col>
                        <Col xs={{ span: 12 }}>
                          <FormikTextarea
                            id="street"
                            label={t('fields.street')}
                            labelClassName="required"
                            fieldName="street"
                            fieldPlaceholder={t('fields.streetAddressDetails')}
                          />
                        </Col>
                      </Row>
                    )}
                  />
                  <AppAccordion
                    accordionHeader={t('titles.credentials')}
                    eventKey="3"
                    accordionContent={(
                      <Row>
                        <Col md={{ span: 6 }}>
                          <FileInput
                            id="personalId"
                            fieldType="file"
                            accept={['image/jpg', 'image/jpeg', 'image/png']}
                            label={t('fields.personalId')}
                            prefix="attachmentsAttributes"
                            fieldName="personalId"
                            hint={t('personalIdHint')}
                            setFieldValue={(value) => {
                              setFieldValue('attachmentsAttributes',
                                {
                                  ...values.attachmentsAttributes, ...value,
                                });
                            }}
                          />
                        </Col>
                        <Col md={{ span: 6 }}>
                          <DragDropFileInput
                            id="professionalLicenses"
                            accept={['image/jpg', 'image/jpeg', 'image/png', '.pdf']}
                            label={t('fields.practiceLicense')}
                            prefix="attachmentsAttributes"
                            maxFiles={3}
                            fieldName="professionalLicenses"
                            fieldPlaceholder={(
                              <span className="dropzone-placeholder">
                                <FontAwesomeIcon icon={faImages} />
                                {t('uploader:dragDrop')}
                                <span className="text-primary">{t('uploader:browse')}</span>
                                <span className="hint">{t('uploader:hints')}</span>
                              </span>
                            )}
                            hint={t('practiceLicenseHint')}
                            setFieldValue={(value) => {
                              setFieldValue('attachmentsAttributes',
                                {
                                  ...values.attachmentsAttributes, ...value,
                                });
                            }}
                            isMulti
                          />
                        </Col>
                      </Row>
                    )}
                  />
                  <AppAccordion
                    accordionHeader={t('titles.professionalInfo')}
                    eventKey="3"
                    accordionContent={(
                      <Row>
                        <Col md={{ span: 6 }}>
                          <div className="mb-3">
                            <FormikRadioButton
                              title={t('fields.qualification')}
                              titleClassName="required"
                              fieldName="qualificationCategory"
                              className="title-label default-radio w-50"
                              radioBtnItems={qualificationItems}
                            />
                          </div>
                        </Col>
                        <Col md={{ span: 6 }}>
                          <FormikInput
                            labelClassName="required"
                            id="practiceSince"
                            label={t('fields.experienceYear')}
                            fieldPlaceholder={t('year')}
                            fieldType="number"
                            fieldName="practiceSince"
                            minorLabel={t('fields.practicingSince')}
                          />
                        </Col>
                        <Col>
                          <DragDropFileInput
                            id="qualificationCertificates"
                            accept={['image/jpg', 'image/jpeg', 'image/png', '.pdf']}
                            label={t('fields.certificates')}
                            prefix="attachmentsAttributes"
                            fieldName="qualificationCertificates"
                            fieldPlaceholder={(
                              <span className="dropzone-placeholder">
                                <FontAwesomeIcon icon={faImages} />
                                {t('uploader:dragDrop')}
                                <span className="text-primary">{t('uploader:browse')}</span>
                                <span className="hint">{t('uploader:hints')}</span>
                              </span>
                            )}
                            hint={t('certificatesHint')}
                            setFieldValue={(value) => {
                              setFieldValue('attachmentsAttributes',
                                {
                                  ...values.attachmentsAttributes, ...value,
                                });
                            }}
                            isMulti
                          />
                        </Col>
                      </Row>
                    )}
                  />
                  <AppAccordion
                    accordionHeader={t('titles.specialities')}
                    eventKey="3"
                    accordionContent={(
                      <Row>
                        <Col sm={{ span: 12 }}>
                          <HorizontalAccordion
                            defaultActiveKey={specialities[0].id}
                            accordionItems={specialitiesCheckboxesItems}
                            className="specialities"
                          />
                        </Col>
                      </Row>
                    )}
                  />
                  <Col md={{ span: 12 }} className="d-flex justify-content-end">
                    <Button type="text" text={t('translation:cancel')} variant="transparent" handleBtnClick={() => !!radiologist && navigate('/profile')} disabledBtn={!radiologistProfile} />
                    <Button type="submit" text={t('translation:submit')} variant="primary" disabledBtn={isInFlight} />
                  </Col>
                </Row>
              </Form>
            );
          }}
        />
      </div>
    </Col>
  );
};

RadiologistProfileForm.propTypes = {
  radiologist: PropTypes.shape({
    dbId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    telegramEnabled: PropTypes.bool.isRequired,
    phone: PropTypes.string.isRequired,
    activeContract: PropTypes.shape(
      { dbId: PropTypes.number },
    ).isRequired,
    radiologistProfile: PropTypes.shape({
      nationality: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      street: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      qualificationCategory: PropTypes.string.isRequired,
      practiceSince: PropTypes.number.isRequired,
      dateOfBirth: PropTypes.string.isRequired,
      examTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    }),
  }).isRequired,
};

export default RadiologistProfileForm;
