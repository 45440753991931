/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PropTypes from 'prop-types';

const HelmetWrapper = ({ children, ...props }) => {
  const processChild = (element, idx) => {
    let clonedChild;
    switch (element.type) {
      case 'title':
        if (element.props.children !== process.env.REACT_APP_NAME) {
          clonedChild = React.cloneElement(element, {
            ref: idx,
            children: [element.props.children, process.env.REACT_APP_NAME].join(' | '),
          });
        }
        break;
      default:
    }
    return clonedChild ?? React.cloneElement(element, { ref: idx });
  };

  const modifiedChildren = React.Children.map(children, processChild);
  return (
    <HelmetProvider>
      <Helmet {...props}>{modifiedChildren}</Helmet>
    </HelmetProvider>
  );
};

HelmetWrapper.propTypes = {
  children: PropTypes.element.isRequired,
};

export default HelmetWrapper;
