import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import './RadioButtonStyle.scss';
import FormikErrorMessage from '../FormikErrorMessage/FormikErrorMessage';
import Label from '../Label/Label';

const FormikRadioButton = (props) => {
  const {
    fieldName,
    className,
    title,
    titleClassName,
    radioBtnItems,
  } = props;
  return (
    <div className={`app-radio ${className}`}>
      <Label title={title} titleClassName={titleClassName} />
      {
        radioBtnItems.map((item) => (
          <div key={item.key}>
            <Label htmlFor={item.id} label={item.label} labelClassName={`ms-2 ${item.labelClassName} ${item.checked ? 'checked' : ''}`} />
            <Field
              type="radio"
              id={item.id}
              name={fieldName}
              value={item.radioValue}
              {...(item.handleChange ? { onChange: item.handleChange } : {})}
            />
          </div>
        ))
      }
      <FormikErrorMessage fieldName={fieldName} />
    </div>
  );
};

FormikRadioButton.propTypes = {
  fieldName: PropTypes.string.isRequired,
  className: PropTypes.string,
  title: PropTypes.node,
  titleClassName: PropTypes.string,
  radioBtnItems: PropTypes.arrayOf(
    PropTypes.shape({
      labelClassName: PropTypes.string,
      label: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.number,
      radioValue: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
      ]),
      handleChange: PropTypes.func,
      checked: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
      ]),
    }),
  ).isRequired,
};
FormikRadioButton.defaultProps = {
  className: null,
  title: null,
  titleClassName: '',
};
export default FormikRadioButton;
