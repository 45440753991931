import React from 'react';
import PropTypes from 'prop-types';
import './LabelStyle.scss';

const Label = (props) => {
  const {
    label,
    labelClassName,
    className,
    htmlFor,
    title,
    titleClassName,
  } = props;
  return (
    <span className={className}>
      {title
        ? <span className={`label ${titleClassName}`}>{title}</span>
        : null}
      {label
        ? <label htmlFor={htmlFor} className={`label ${labelClassName}`}>{label}</label>
        : null}
    </span>
  );
};

Label.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.node,
  htmlFor: PropTypes.string,
  title: PropTypes.node,
  titleClassName: PropTypes.string,
};
Label.defaultProps = {
  className: '',
  labelClassName: '',
  label: null,
  htmlFor: null,
  title: null,
  titleClassName: '',
};
export default Label;
