import { ToastContainer } from 'react-toastify';
import MainLayout from './components/Layout/MainLayout';
import { AuthProvider } from './contexts/AuthContext';
import { ActionCableProvider } from './contexts/ActionCableContext';
import ErrorPageHandler from './components/Errors/ErrorPageHandler';
import './assets/scss/BaseStyle/base.scss';
import 'react-toastify/dist/ReactToastify.css';
import './assets/scss/SharedStyle/ToastStyle.scss';

const App = () => (
  <AuthProvider>
    <ActionCableProvider>
      <ErrorPageHandler>
        <div className="App">
          <MainLayout />
          <ToastContainer
            position="bottom-left"
            autoClose={8000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            ltr
            pauseOnFocusLoss
            draggable={false}
            pauseOnHover
          />
        </div>
      </ErrorPageHandler>
    </ActionCableProvider>
  </AuthProvider>
);

export default App;
