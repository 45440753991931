import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import CookieService from '../services/CookieService';
import RefreshTokenMutation from './RefreshTokenMutation/RefreshTokenMutation';
import { relayStore } from '../RelayEnvironment';
import {
  AUTH_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  IS_REMEMBER_ME_KEY,
} from '../utils/constants';
import Loader from '../components/Shared/Loader/Loader';
import toDateTime from '../utils/time';
import LogoutMutation from './LogoutMutation/LogoutMutation';

const AuthContext = createContext();
export default AuthContext;

const { useMutation } = require('react-relay');

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const [hasProfile, setHasProfile] = useState(currentUser !== null
    && currentUser.radiologistProfile !== null);

  const completedProfile = () => (hasProfile
    || (currentUser !== null && currentUser.radiologistProfile !== null));

  const [commitRefresh] = useMutation(RefreshTokenMutation);
  const [commitLogout] = useMutation(LogoutMutation);

  const storedRefreshToken = () => CookieService.get(REFRESH_TOKEN_KEY);
  const setRefreshToken = (tokenKey, tokenValue, options) => {
    CookieService.set(tokenKey, tokenValue, options);
  };

  const getAuthToken = () => relayStore.get(AUTH_TOKEN_KEY);
  const isRememberMe = () => localStorage.getItem(IS_REMEMBER_ME_KEY);

  const isMedicalFacility = () => currentUser?.type === 'MedicalFacility';
  const isRadiologist = () => currentUser?.type === 'Radiologist';

  const saveTokensData = (data, rememberMe=false) => {
    CookieService.remove(REFRESH_TOKEN_KEY);
    relayStore.set(AUTH_TOKEN_KEY, data.authToken);
    setCurrentUser(data.user);
    let options = {
      path: '/',
      secure: (process.env.NODE_ENV !== 'development'),
      sameSite: 'strict',
    };
    if (rememberMe) {
      options = {
        ...options,
        expires: toDateTime(data.authRefreshTokenExpiry),
      };
    }
    setRefreshToken(REFRESH_TOKEN_KEY, data.authRefreshToken, options);
    localStorage.setItem(IS_REMEMBER_ME_KEY, rememberMe);
  };

  const navigate = useNavigate();

  const logoutUser = () => {
    if (storedRefreshToken() !== undefined) {
      setLogoutLoading(true);
      commitLogout({
        variables: {
          input: { refreshToken: storedRefreshToken() },
        },
        onCompleted: () => {
          navigate('/login');
          setLogoutLoading(false);
          setCurrentUser(null);
          CookieService.remove(REFRESH_TOKEN_KEY);
          localStorage.setItem(IS_REMEMBER_ME_KEY, false);
          relayStore.delete(AUTH_TOKEN_KEY);
          relayStore.clear();
        },
        onError: () => {
          setLogoutLoading(false);
        },
      });
    } else {
      setLogoutLoading(false);
    }
  };

  const updateToken = async () => {
    if (storedRefreshToken() !== undefined) {
      const input = {
        refreshToken: storedRefreshToken(),
      };
      commitRefresh({
        variables: {
          input,
        },
        onCompleted: ({ refreshToken }) => {
          setLoading(false);

          if (refreshToken) {
            saveTokensData(refreshToken, isRememberMe());
          } else {
            logoutUser();
          }
        },
        onError: () => {
          setLoading(false);
          logoutUser();
        },
      });
    } else {
      setLoading(false);
    }
  };

  const isAuthenticated = !!currentUser;

  const contextData = {
    currentUser,
    saveTokensData,
    logoutUser,
    isAuthenticated,
    isMedicalFacility,
    isRadiologist,
    completedProfile,
    setHasProfile,
    getAuthToken,
  };

  useEffect(() => {
    if (loading) {
      updateToken();
    }
    const authToken = getAuthToken();
    if (authToken) {
      const { exp, iat } = jwt_decode(authToken);
      const updateTokenAfter = (exp-iat-60)*1000; // Expiry duration - 1 minute ( 60 secs )
      const interval = setInterval(() => {
        updateToken();
      }, updateTokenAfter);
      return () => clearInterval(interval);
    }
    return '';
  }, [getAuthToken(), loading]);

  if (loading || logoutLoading) { return <Loader />; }

  return (
    <AuthContext.Provider value={contextData}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
