import graphql from 'babel-plugin-relay/macro';

const EditProfileQuery = graphql`
    query EditProfileQuery {
        nationalities{
            nationality
        }
        countries{
            name
            alpha2
        }
        specialities {
            id
            dbId
            name
            examTypes {
                id
                dbId
                name
            }
        }
    }
`;

export default EditProfileQuery;
