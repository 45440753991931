import graphql from 'babel-plugin-relay/macro';

const CreateProfileMutation = graphql`
  mutation CreateProfileMutation($input: CreateRadiologistProfileInput!){
    createRadiologistProfile(input: $input){
      radiologist{
        ...ViewRadProfileQueryFragment
      }
      errors
    }
  }
`;

export default CreateProfileMutation;
