import graphql from 'babel-plugin-relay/macro';

const RadiologistQuery = graphql`
  query RadiologistQuery {
    radiologist {
      dbId
      name
      email
      phone
      telegramEnabled
      activeContract {
        dbId
      }
      radiologistProfile {
        gender
        dateOfBirth
        nationality
        country
        city
        street
        examTypeIds
        qualificationCategory
        practiceSince
      }
    }
  }
`;

export default RadiologistQuery;
