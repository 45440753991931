import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './i18n';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import RelayEnvironment from './RelayEnvironment';
import Loader from './components/Shared/Loader/Loader';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Router>
      <ErrorBoundary>
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <Suspense fallback={<Loader />}>
            <StrictMode>
              <App />
            </StrictMode>
          </Suspense>
        </RelayEnvironmentProvider>
      </ErrorBoundary>
    </Router>
  </Suspense>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
