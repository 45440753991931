import React, { useState, useContext } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Formik, Form } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useLocation } from 'react-router-dom';
import ValidationSchema from './ValidationSchema';
import './LoginStyle.scss';
import logo from '../../assets/images/logo.png';
import FormikInput from '../Shared/Form/FormikInput/FormikInput';
import Btn from '../Shared/Button/Button';
import FormikCheckboxes from '../Shared/Form/FormikCheckboxes/FormikCheckboxes';
import FlashMsg from '../Shared/FlashMsg/FlashMsg';
import HelmetWrapper from '../../hocs/HelmetWrapper/HelmetWrapper';
import LoginMutation from './LoginMutation/LoginMutation';
import AuthContext from '../../contexts/AuthContext';
import AlertContext from '../../contexts/AlertContext';

const checkboxItems = [
  {
    fieldText: 'Remember Me',
    fieldName: 'rememberMe',
    key: 1,
    fieldFor: 'checkRememberMe',
  },
];

const { useMutation } = require('react-relay');

const Login = () => {
  const { t } = useTranslation(['login', 'translation', 'errors']);
  const [commit] = useMutation(LoginMutation);
  const { REACT_APP_BACK_END_URL } = process.env;
  // Contexts
  const { saveTokensData } = useContext(AuthContext);
  const { showAlert } = useContext(AlertContext);
  // States
  const [loginErrorMsg, setloginErrorMsg] = useState();
  const [displayErrorMsg, setdisplayErrorMsg] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const initialValues = {
    email: '',
    password: '',
    rememberMe: false,
  };

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    const input = {
      email: values.email,
      password: values.password,
    };

    commit({
      variables: {
        input,
      },
      onCompleted: ({ login }) => {
        setSubmitting(false);
        if (login !== null) {
          resetForm();
          saveTokensData(login, values.rememberMe);
          if (location.pathname === '/' || location.pathname === '/login') {
            navigate('/job-requests');
          } else {
            navigate(1); // Go back to previous location
          }
          showAlert(t('login:success'));
        }
      },
      onError: ({ message }) => {
        setSubmitting(false);
        setloginErrorMsg(message);
        setdisplayErrorMsg(true);
      },
    });
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <div className="login-container">
      <HelmetWrapper>
        <title>{t('login:login')}</title>
      </HelmetWrapper>
      <Container>
        <Row>
          <Col>
            <img src={logo} alt={t('login:expertEye')} />
          </Col>
        </Row>
        <Row className="justify-content-md-end">
          <Col lg={{ span: 6 }} md={{ span: 8 }}>
            <h1 className="mx-md-5">
              {t('login:eISolution')}
              <span>{t('login:expertEye')}</span>
            </h1>
            <div className="main-card mx-md-5">
              <h4>{t('login:welcomeBack')}</h4>
              <p>{t('login:loginToUse')}</p>
              <Formik
                initialValues={initialValues}
                validationSchema={ValidationSchema}
                onSubmit={onSubmit}
              >
                {
                  ({
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <FlashMsg
                        variant="danger"
                        errorMessage={loginErrorMsg}
                        isDisplayed={displayErrorMsg}
                      />
                      <FormikInput
                        className="mb-4"
                        labelClassName="required"
                        label={t('login:email')}
                        id="email"
                        fieldType="email"
                        fieldName="email"
                        fieldPlaceholder={t('login:enterEmail')}
                        errorMessage={(message) => (t(message.key, message.values))}
                        fieldTestId="TestId-email"
                      />
                      <FormikInput
                        className="mb-2 p-relative"
                        labelClassName="required"
                        label={t('login:password')}
                        id="password"
                        fieldType={passwordShown ? 'text' : 'password'}
                        fieldName="password"
                        fieldPlaceholder={t('login:password')}
                        icon={(
                          <FontAwesomeIcon
                            onClick={togglePassword}
                            icon={passwordShown ? faEye : faEyeSlash}
                          />
                        )}
                        errorMessage={(message) => (t(message.key, message.values))}
                        fieldTestId="TestId-password"
                      />
                      <FormikCheckboxes
                        className="mb-4 checkbox-container"
                        checkboxItems={checkboxItems}
                        fieldName="rememberMe"
                      />
                      <a
                        href={`${REACT_APP_BACK_END_URL}/users/password/new`}
                        className="forget-password"
                      >
                        {t('forgotPassword')}

                      </a>
                      <Btn
                        variant="primary"
                        type="submit"
                        disabledBtn={isSubmitting}
                        text={isSubmitting ? t('translation:wait') : t('login:login')}
                        className="w-100"
                        dataTestId="TestId-submit"
                      />
                    </Form>
                  )
                }
              </Formik>
            </div>
            <p className="footer">
              <Trans i18nKey="footer" count={new Date().getFullYear()}>
                <span className="px-1" />
              </Trans>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
