import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import './ErrorsStyle.scss';
import CustomLink from '../Shared/Link/CustomLink';
import error404bg from '../../assets/images/error404bg.png';

const Error404 = () => {
  const { t } = useTranslation('errorPages');
  const location = useLocation();
  return (
    <div className="error-page error-404">
      <div className="container">
        <img src={error404bg} alt={t('errorPages:error404')} />
        <p>{t('errorPages:error404')}</p>

        {
          (location.pathname !== '/')
          && (
            <CustomLink
              to="/"
              text={t('errorPages:goToHomepage')}
              className="primary-link"
            />
          )
        }
      </div>
    </div>
  );
};

export default Error404;
