import PropTypes from 'prop-types';
import AppButton from 'react-bootstrap/Button';
import './ButtonStyle.scss';

const Button = (props) => {
  const {
    text,
    size,
    handleBtnClick,
    variant,
    disabledBtn,
    className,
    type,
    dataTestId,
    title,
    dataPlacement,
  } = props;
  return (
    <AppButton
      variant={variant}
      onClick={handleBtnClick}
      disabled={disabledBtn}
      size={size}
      className={className}
      type={type}
      data-testid={dataTestId}
      title={title}
      data-toggle={title ? 'tooltip' : null}
      data-placement={dataPlacement}
    >
      {text}
    </AppButton>
  );
};

Button.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.string,
  type: PropTypes.string,
  handleBtnClick: PropTypes.func,
  variant: PropTypes.string.isRequired,
  disabledBtn: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  dataPlacement: PropTypes.string,
  title: PropTypes.string,
};
Button.defaultProps = {
  size: '',
  handleBtnClick: () => {},
  disabledBtn: false,
  className: '',
  type: '',
  dataTestId: '',
  dataPlacement: null,
  title: '',
};

export default Button;
