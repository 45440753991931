import * as Yup from 'yup';
import { includesAny } from '../../utils/strings';
import validationMessages from '../../utils/validationMessages';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const ValidationSchema = Yup.object({
  name: Yup.string()
    .max(50, validationMessages.minChars(50))
    .required(validationMessages.requiredField()),
  dateOfBirth: Yup.date()
    .required(validationMessages.requiredField())
    .max(new Date(), validationMessages.dateNotInFuture()),
  phone: Yup.string()
    .required(validationMessages.requiredField())
    .matches(phoneRegExp, validationMessages.invalidNumber),
  email: Yup.string()
    .email(validationMessages.invalidEmail())
    .required(validationMessages.requiredField()),
  gender: Yup.string()
    .required(validationMessages.requiredField()),
  nationality: Yup.string()
    .required(validationMessages.requiredField()),
  street: Yup.string()
    .required(validationMessages.requiredField())
    .max(100, validationMessages.minChars(100)),
  country: Yup.string()
    .required(validationMessages.requiredField()),
  city: Yup.string()
    .required(validationMessages.requiredField()),
  qualificationCategory: Yup.string()
    .required(validationMessages.requiredField()),
  practiceSince: Yup.string()
    .required(validationMessages.requiredField()),
  examTypeIds: Yup.array()
    .min(1, validationMessages.min1Select()),
  attachmentsAttributes: Yup.object({
    personalId: Yup.mixed()
      .test('type must be on of: jpg, jpeg, png', validationMessages.typeFile(['jpg', 'jpeg', 'png'].join(', ')), (file) => !file || includesAny(file.type, 'jpg', 'jpeg', 'png'))
      .test('must be at least 5 KB', validationMessages.smFile(5, 'KB'), (file) => !file || file.size >= 5120)
      .test('must be at most 10 MB', validationMessages.lgFile(10, 'MB'), (file) => !file || file.size <= 10485760),
    professionalLicenses: Yup.array()
      .test('chosen file has errors', validationMessages.filesHasErrors(), (files) => !files?.length || !files.some((file) => file.errors)),
    qualificationCertificates: Yup.array()
      .test('chosen file has errors', validationMessages.filesHasErrors(), (files) => !files?.length || !files.some((file) => file.errors)),
  }),
});

export default ValidationSchema;
