import { useEffect } from 'react';
import { useError } from '../components/Errors/ErrorPageHandler';
import { NOT_FOUND_ERROR } from '../components/Errors/ErrorCodes';

export default () => {
  const setError = useError();

  useEffect(() => {
    setError(NOT_FOUND_ERROR);
  }, []);

  return null;
};
