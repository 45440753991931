import graphql from 'babel-plugin-relay/macro';

const LogoutMutation = graphql`
  mutation LogoutMutation($input: LogoutInput!) {
    logout(input: $input){
      authToken
      authRefreshToken
    }
  }
`;

export default LogoutMutation;
