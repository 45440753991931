import graphql from 'babel-plugin-relay/macro';

const UpdateProfileMutation = graphql`
  mutation UpdateProfileMutation($input: UpdateRadiologistProfileInput!){
    updateRadiologistProfile(input: $input){
      radiologist {
        ...ViewRadProfileQueryFragment
      }
      errors
    }
  }
`;

export default UpdateProfileMutation;
