import * as Yup from 'yup';
import validationMessages from '../../utils/validationMessages';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(validationMessages.invalidEmail())
    .required(validationMessages.requiredField()),
  password: Yup.string()
    .required(validationMessages.requiredField()),
});

export default ValidationSchema;
